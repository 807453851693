'use client';

import { Button, Input } from '@/components/design-system';
import { Link, useRouter } from '@/i18n/routing';
import { useFormik } from 'formik';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { signInWith } from '@/actions/auth';
import * as leanplum from '../../../../../lib/leanplum';
import useSession from '../../../../../stores/session/useSession';
import AppleLogin from './AppleLogin';
import FacebookLogin from './FacebookLogin';
import { Spinner } from '@/components/design-system/Spinner';

interface Props {
  redirect?: string;
  error?: string;
}

const schema = z.object({
  email: z
    .string({ required_error: 'form.errors.required' })
    .email({ message: 'form.errors.email' }),
  password: z
    .string({ required_error: 'form.errors.required' })
    .min(6, { message: 'form.errors.min' }),
});

export default function FormLogin(props: Props) {
  const t = useTranslations('common');
  const [error, setError] = useState(props.error);
  const router = useRouter();
  const { setSession } = useSession();
  const {
    handleChange,
    handleSubmit,
    isSubmitting,
    values: { email, password },
    errors,
  } = useFormik({
    validationSchema: toFormikValidationSchema(schema),
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ email, password }) => {
      const { ok, error, user, token } = await signInWith('local', {
        email,
        password,
      });
      leanplum.recordEvent('login');
      if (!ok && error) {
        return setError(error);
      }
      setSession({ user, token });
      router.replace('/');
    },
  });

  useEffect(() => {
    setError('');
  }, [email, password]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  return (
    <form onSubmit={handleSubmit}>
      <Input
        leftIcon={
          <span className="text-gray-500 inline-block mt-[-3px] absolute top-[50%] left-[15px] -translate-y-2/4">
            <i className="icon-mail"/>
          </span>
        }
        disabled={isSubmitting}
        name="email"
        value={email}
        onChange={handleChange}
        placeholder="Email"
        className="mb-4"
        {...(errors.email && { errorMessage: t(errors.email) })}
      />
      <Input
        leftIcon={
          <span className="text-gray-500 inline-block mt-[-3px] absolute top-[50%] left-[15px] -translate-y-2/4">
            <i className="icon-lock" />
          </span>
        }
        type="password"
        name="password"
        value={password}
        onChange={handleChange}
        disabled={isSubmitting}
        placeholder="Password"
        {...(errors.password && { errorMessage: t(errors.password) })}
      />
      <div className="mt-2 flex flex-wrap justify-end pb-2">
        <Link
          className="text-sm text-primary hover:text-primary underline hover:no-underline"
          href={'/reset-password'}
          replace>
          {t('FormLogin.forgot-password?')}
        </Link>
      </div>
      {error && (
        <div className="flex gap-2 flex-row items-center mb-4 p-4 rounded-sm bg-red-200 text-red-700 text-base">
          {t(
            [
              'email already registered with another strategies',
              'email-exists',
            ].includes(error)
              ? 'FormLogin.email-already-registered-with-another-auth-strategy'
              : 'FormLogin.login-error',
          )}
        </div>
      )}

      <Button disabled={isSubmitting} type="submit" className="w-full">
        {t('FormLogin.login')}
        {isSubmitting && (
          <Spinner size='small' className="ml-2"/>
        )}
      </Button>
      <div className="text-base text-center my-4 capitalize text-gray-500">
        {t.rich('FormLogin.or', {})}
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <FacebookLogin />
        <AppleLogin />
      </div>

      <hr className="my-3" />
      <div className="w-full text-center text-sm text-gray-500">
        <span>{t('FormLogin.not-registered-yet?')}</span>{' '}
        <Link
          className="text-primary hover:text-primary underline hover:no-underline"
          href={{
            pathname: '/auth/signup',
            query: {
              redirect: props.redirect,
            },
          }}
          replace={true}
        >
          <b>{t('FormLogin.signup-now!')}</b>
        </Link>
      </div>
    </form>
  );
}
