import {
  createNavigation,
} from 'next-intl/navigation';
import {defineRouting} from 'next-intl/routing';

export const routing = defineRouting({
  locales: ['it', 'en'],
  defaultLocale: 'it',
  localePrefix: 'always',
  pathnames:  {
    '/': '/',
    '/auth/signin': '/auth/signin',
    '/auth/signup': '/auth/signup',
    '/privacy': '/privacy',
    '/checkout': '/checkout',
    '/email-unsubscribe': '/email-unsubscribe',
    '/giftcard': '/giftcard',
    '/riscatta-giftcard': '/riscatta-giftcard',
    '/reset-password': '/reset-password',
    '/campagne-partner/enilive': '/campagne-partner/enilive',
    '/campagne-partner/enilive/success': '/campagne-partner/enilive/success',
    '/about': {
      it: '/chi-siamo',
      en: '/about',
    },
    '/ourstory': {
      it: '/la-nostra-storia',
      en: '/our-story',
    },
    '/press': {
      it: '/stampa',
      en: '/press',
    },
    '/gifts': {
      it: '/regali-aziendali',
      en: '/corporate-gifts',
    },
    '/terms': {
      it: '/termini-e-condizioni',
      en: '/terms-and-conditions',
    },
    '/produttori': {
      it: '/produttori',
      en: '/producers',
    },
    '/diventa-nostro-affiliato': {
      it: '/diventa-nostro-affiliato',
      en: '/become-our-affiliate',
    },
    '/campaign/[slug]': {
      it: '/campagna/[slug]',
      en: '/campaign/[slug]',
    },
    '/diventa-nostro-rider': {
      it: '/diventa-nostro-rider',
      en: '/become-a-rider',
    },
    '/create-your-cocktail': {
      it: '/crea-il-tuo-cocktail',
      en: '/create-your-cocktail',
    },
    '/create-your-cocktail/[flow]/review': {
      it: '/crea-il-tuo-cocktail/[flow]/review',
      en: '/create-your-cocktail/[flow]/review',
    },
    '/create-your-cocktail/[flow]/[step]': {
      it: '/crea-il-tuo-cocktail/[flow]/[step]',
      en: '/create-your-cocktail/[flow]/[step]',
    },
    '/direttamente-dal-produttore': {
      it: '/direttamente-dal-produttore',
      en: '/directly-from-the-producer',
    },
    '/fai-un-regalo': {
      it: '/fai-un-regalo',
      en: '/make-a-gift',
    },
    '/delivery/[slug]': {
      it: '/consegna/[slug]',
      en: '/delivery/[slug]',
    },
    '/business/eventi-aziendali': {
      it: '/business/eventi-aziendali',
      en: '/business/corporate-events',
    },
    '/business/eventi-privati': {
      it: '/business/eventi-privati',
      en: '/business/private-events',
    },
    '/business/eventi-aziendali/eventi-virtuali': {
      it: '/business/eventi-aziendali/eventi-virtuali',
      en: '/business/corporate-events/virtual-events',
    },
    '/business/eventi-aziendali/eventi-in-presenza': {
      it: '/business/eventi-aziendali/eventi-in-presenza',
      en: '/business/corporate-events/physical-events',
    },
    '/business/eventi-aziendali/regalistica-aziendale': {
      it: '/business/eventi-aziendali/regalistica-aziendale',
      en: '/business/corporate-events/corporate-gifting',
    },
    '/bar': {
      it: '/bar-e-enoteca',
      en: '/bar-and-wine-shop',
    },
    '/bar/products': {
      it: '/bar-e-enoteca/prodotti',
      en: '/bar-and-wine-shop/products',
    },
    '/bar/[barId]': {
      it: '/bar-e-enoteca/[barId]',
      en: '/bar-and-wine-shop/[barId]',
    },
    '/faq': {
      it: '/faq',
      en: '/faq',
    },
    '/shop': {
      it: '/prodotti',
      en: '/products',
    },
    '/shop/[id]': {
      it: '/dettaglio/[id]',
      en: '/details/[id]',
    },
    '/partners/[slug]': '/partners/[slug]',
    '/category[category]': {
      it: '/categoria/[category]',
      en: '/category/[category]',
    },
    '/profile': {
      it: '/profilo',
      en: '/profile',
    },
    '/profile/giftcards': {
      it: '/profilo/giftcards',
      en: '/profile/giftcards',
    },
    '/profile/addresses': {
      it: '/profilo/indirizzi',
      en: '/profile/addresses',
    },
    '/profile/payment-methods': {
      it: '/profilo/metodi-di-pagamento',
      en: '/profile/payment-methods',
    },
    '/profile/orders': {
      it: '/profilo/ordini',
      en: '/profile/orders',
    },
    '/profile/orders/[id]': {
      it: '/profilo/ordini/[id]',
      en: '/profile/orders/[id]',
    },
    '/profile/orders/experiences/[id]': {
      it: '/profilo/ordini/esperienze/[id]',
      en: '/profile/orders/experiences/[id]',
    },
    '/experience': {
      it: '/esperienze',
      en: '/experience',
    },
    '/experience/experience-detail/[id]': {
      it: '/esperienze/dettaglio-esperienza/[id]',
      en: '/experience/experience-detail/[id]',
    },
    '/experience/provider-detail/[id]': {
      it: '/esperienze/dettaglio-host/[id]',
      en: '/experience/provider-detail/[id]',
    },
    '/experience/checkout-complete': {
      it: '/esperienze/checkout-completato',
      en: '/experience/checkout-complete',
    },
    '/experience/checkout': {
      it: '/esperienze/checkout',
      en: '/experience/checkout',
    },
    '/wallet': {
      it: '/tappadanaio',
      en: '/wallet',
    },
    '/wallet/checkout': {
      it: '/tappadanaio/checkout',
      en: '/wallet/checkout',
    },
    '/wallet/shop': {
      it: '/tappadanaio/shop',
      en: '/wallet/shop',
    },
    '/wallet/prize/[id]': {
      it: '/tappadanaio/premio/[id]',
      en: '/wallet/prize/[id]',
    },
    '/profile/wishlist': {
      it: '/profilo/i-miei-preferiti',
      en: '/profile/wishlist',
    },
    '/profile/recently-purchased': {
      it: '/profilo/acquistati-di-recente',
      en: '/profile/recently-purchased',
    },
    '/search-results': {
      it: '/risultati-ricerca',
      en: '/search-results',
    },
  },
  alternateLinks: true,
  localeDetection: true,
});

export const {Link, redirect, usePathname, useRouter, getPathname} = createNavigation(routing);
