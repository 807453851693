'use client';

import { appleOAuth } from '@/actions/auth';
import { Button } from '../../../../../components/design-system';
import { useTranslations } from 'next-intl';

export default function AppleLogin() {
  const t = useTranslations('common');

  return (
    <Button
      type="button"
      className="w-full to-[#000]! from-[#000]! border-[#000]! text-white!"
      onClick={() => appleOAuth()}
    >
      <i className="socicon-apple mr-1" />
      <span>{t('FormLogin.apple-login')}</span>
    </Button>
  );
}
