'use client';

import { useTranslations } from 'next-intl';
import { facebookOAuth } from '../../../../../actions/auth';
import { Button } from '../../../../../components/design-system';

export default function FacebookLogin() {
  const t = useTranslations('common');

  return (
    <Button
      type="button"
      className="w-full to-[#3b5998]! from-[#3b5998]! border-[#3b5998]! text-white!"
      onClick={() => facebookOAuth()}
    >
      <i className="socicon-facebook mr-1" />
      <span>{t('FormLogin.facebook-login')}</span>
    </Button>
  );
}
