/**
 * Link component
 */
interface IProps {
  size: 'large' | 'medium' | 'small';
  className?: string;
  id?: string;
  style?: object;
}

export const Spinner = (
  {
    size,
    className,
    style,
    id,
  }: IProps = {
    size: 'medium',
    className: '',
    style: {},
  },
) => {
  const classes: string[] = [];

  if (size === 'small') {
    classes.push('w-4', 'h-4', 'border-2');
  } else if (size === 'medium') {
    classes.push('w-8', 'h-8', 'border-4');
  } else if (size === 'large') {
    classes.push('w-12', 'h-12', 'border-[6px]');
  }

  return (
    <div
      id={id}
      className={'animate-spin inline-block align-text-bottom rounded-full border-current border-r-transparent ' +
        classes.join(' ') + ' ' + className}
      style={style}
    />
  );
};
