'use client';

import FormLogin from '@/app/[locale]/auth/signin/components/FormLogin';
import {IMGPW} from '@/config';
import {Link} from '@/i18n/routing';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import {useSearchParams} from 'next/navigation';

export default function Content({error}: {error?: string}) {
  const t = useTranslations('signin');
  const searchParams = useSearchParams();

  return (
    <>
      <header className="sticky border-b h-20 border-greyscale-gray-light bg-white">
        <div className="absolute h-full top-0 left-0 z-5">
          <div className="h-full min-h-full table ml-4">
            <Link href="/" as="/" className="table-cell align-middle w-[154px] py-3 no-underline pl-0">
              <Image
                src={`${IMGPW}/winelivery.png`}
                className="block w-full"
                width={100}
                height={100}
                alt="winelivery logo"
              />
            </Link>
          </div>
        </div>
      </header>
      <div className="container mx-auto">
        <div
          className="flex flex-wrap justify-center items-center"
          style={{minHeight: '60vh'}}
        >
          <div className="mx-4 my-8 md:w-3/4 lg:w-1/2 px-0">
            <h3 className="text-3xl font-bold text-gray-700 mb-3">{t('sign-in')}</h3>
            <p className="text-base mb-4 text-gray-500">{t('insert-credentials')}</p>
            <FormLogin
              error={error}
              redirect={searchParams?.get('redirect') || undefined}
            />
          </div>
        </div>
      </div>
    </>
  );
}
